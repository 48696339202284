
<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <div class="layer2">
              <div class="title1">在线方案设计及优化</div>
              <div class="title2">基于油藏地质数据库，通过后台专家+人工智能的技术支持系统完成各类方案设计，并根据各场景中获取的如钻井、压裂、生产动态数据实时进行优化方案调整，为客户、安东内部生态企业、油气田开发研究院提供高效开发方案设计及方案实时迭代优化服务。</div>
              <div class="titlle3" @click="clickConsult('地质工程云平台')"> 产品咨询</div>
            </div>
            <div class="layer3"></div>
          </div>

          <div class="screennew"  style="background-color: white;padding-top: 0"  ref="consultMove">
            <div class="title1">
              油气开发GPT应用，全行业提供优质服务
            </div>
            <div class="title2">
              油气田开发研究院+GPT应用赋能油气行业
            </div>
          </div>
          <div class="screenMake4"  style="background-color: white;padding-top: 0"  ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:5vh  auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand">
                <div class="back " @click="recognitionlayer">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-bottom-left-radius: 9px" src="@/assets/gpt33.png"/>
                  </div>
                  <div class="titleDesign" style="margin:auto">
                    <div class="title1">
                      基于压裂参数的EUR预测<br>及参数反推平台



                    </div>
                    <div class="title2">
                      通过先进的数据分析技术，结合压裂参数，为您分析预计采收储量（EUR）。不仅如此，系统还能根据EUR值反向推算关键参数，实现历史记录的深度挖掘和相关性分析，帮助您优化油藏管理和提高采收效率。
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
              <headtip @showDialog="showPartner"></headtip>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">在线方案设计及优化</div>
              <div class="title2">基于油藏地质数据库，通过后台专家+人工智能的技术支持系统完成各类方案设计，并根据各场景中获取的如钻井、压裂、生产动态数据实时进行优化方案调整，为客户、安东内部生态企业、油气田开发研究院提供高效开发方案设计及方案实时迭代优化服务。</div>
            </div>
            <div class="titlle3" @click="clickConsult('地质工程云平台')">
              产品咨询
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screennew"  style="background-color: white;padding-top: 0"  ref="consultMove">
            <div class="title1">
              油气开发GPT应用，全行业提供优质服务
            </div>
            <div class="title2">
              油气田开发研究院+GPT应用赋能油气行业
            </div>
          </div>
          <div class="screenMake4"  style="background-color: white;padding-top: 0"  ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:5vh  auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand">
                <div class="back " @click="recognitionlayer">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/gpt33.png"/>
                  </div>
                  <div class="titleDesign" style="margin:auto">
                    <div class="title1">
                      基于压裂参数的EUR预测<br>及参数反推平台



                    </div>
                    <div class="title2">
                      通过先进的数据分析技术，结合压裂参数，为您分析预计采收储量（EUR）。不仅如此，系统还能根据EUR值反向推算关键参数，实现历史记录的深度挖掘和相关性分析，帮助您优化油藏管理和提高采收效率。
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import recognition from "@/views/new/recognition.vue";
export default {
  name: "",
  computed: {
    recognition() {
      return recognition
    }
  },
  props: [],
  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {
      title:'',
      pageTitle:  '在线方案设计及优化-油气藏综合地质评价服务-地质工程云平台GPT',
      pageDescription: '基于油藏地质数据库，通过后台专家+人工智能的技术支持系统完成各类方案设计，并根据各场景中获取的如钻井、压裂、生产动态数据实时进行优化方案调整，为客户、安东内部生态企业、油气田开发研究院提供高效开发方案设计及方案实时迭代优化服务。',
      pageKeywords: '在线方案设计及优化,油气藏综合地质评价服务,地质工程云平台GPT',
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/modeling4.png"),

        title3:"" +
            "<li>四川盆地CN地区煤层气地质工程一体化开发方案</li>" +
            "<li>金山气田地质工程一体化风险总包实施方案\t</li>" +
            "<li>长宁煤层气地质工程一体化开发方案</li>" +
            "<li>贵州六枝牛场区块煤矿瓦斯综合治理——地质工程一体化总包服务</li>"
      },
      ]
    }

  },


  methods: {
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime(){
      window.open("/realtime")
    },
    recognitionlayer(){
      window.open("/eur")
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle:  '在线方案设计及优化-油气藏综合地质评价服务-地质工程云平台GPT',
        pageDescription: '基于油藏地质数据库，通过后台专家+人工智能的技术支持系统完成各类方案设计，并根据各场景中获取的如钻井、压裂、生产动态数据实时进行优化方案调整，为客户、安东内部生态企业、油气田开发研究院提供高效开发方案设计及方案实时迭代优化服务。',
        pageKeywords: '在线方案设计及优化,油气藏综合地质评价服务,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 70vh;

      padding: 2.84301606922126vh 1.5vw  2.84301606922126vh 5vw;
      background-image: url("../../assets/datainnerbackground.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }
        .titlle3{
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 13.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        row-gap: 2vh;
        .grand {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0;
          opacity: 1;
          display: grid;
          grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) ;
          row-gap: 5vh;
          column-gap: 1vw;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: #bfd8f9;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            //display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            width: 100%;
            margin:0 auto;
            height: 35vh;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: center;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;

            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            margin:1vh auto;;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
          .titleDesign{
            padding: 2vh 3vw;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }
      }



    }
    .screennew{
      background-image: url("../../assets/datainner.webp");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      height: 40vh;
      position: relative;

      .title1{
        position: absolute;
        color: white;
        margin:10vh auto;
        text-align: center;
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
        font-size: 2.2vw;
        font-weight: bold;
      }
      .title2{
        position: absolute;
        color: white;
        margin:22vh auto;
        text-align: center;
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
        font-size: 2vw;
      }
    }
    .screenMake4 {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }
      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        row-gap: 2vh;
        .grand {
          cursor: pointer;
          width: 60%;
          height:fit-content;
          padding: 0.98887515451174vh 0;
          opacity: 1;
          margin:0 auto;
          display: grid;
          grid-template-columns: minmax(0,1.5fr);

          row-gap: 5vh;
          column-gap: 1vw;
          .back {
            transition:transform 0.3s ease-out;
            width: 100%;
            position: relative;
            height: 100%;
            background: #bfd8f9;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .photo {
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              background-color: transparent; /* 这里设置背景颜色为透明 */
              opacity: 1.25;
              width: fit-content;
              margin:auto 0;
              height: 100%;
            }


            .title1 {
              padding: 0;
              opacity: 4;
              text-align: center;
              font-size: 1vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: bold;
              line-height: 3vh;
            }

            .title2 {
              opacity: 4;
              text-align: justify;
              margin:2vh auto;;
              justify-content: center;
              align-items: center;
              font-size:0.9vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.71940667490729vh;
            }
            .titleDesign{
              padding: 2vh 3vw;
              float: right;
              width: 25vw;
              text-align: justify;
            }
          }
        }
        .back:hover {
          transform: translateY(-10px);
        }
      }
    }
  }
}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      //z-index: 3;
      position: relative;
      width: 100%;
      height: 400px;

      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/groundinnerbannerM.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .titlle3{
        position: absolute;
        bottom:50px;
        left:50%;
        transform: translateX(-60%);
        font-size: 17px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 25px;
        height: 40px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

      .title4{
        position: absolute;
        left:10%;
        bottom:25px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: 18px;
        font-size: 10px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: white;
        line-height: 14px;
      }

      .layer2 {
        // position: absolute;
        // left:0;
        margin-top:20px;
        .title1 {
          margin:0 auto;
          width: fit-content;
          text-align: center;
          height: fit-content;
          font-size: 23px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: white;

          line-height:32px;
        }

        .title2 {
          margin: 20px auto 0 auto;
          width: 90%;
          text-align: left;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
          line-height: 24px;
        }

        .title3 {
          margin: 15px auto;
          width: fit-content;
          text-align: left;
          height: fit-content;
          font-size: 12px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color:white;
          line-height: 24px;
        }
      }

    }
    .screennew{
      background-image: url("../../assets/datainner.webp");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      height: fit-content;
      position: relative;

      .title1{
        color: white;
        padding: 35px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
      .title2{
        margin-top: 25px;
        padding-bottom:35px ;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
      }
    }
    .screenMake4 {

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .title {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;

      }

      .light {
        margin: 19px 2vw;

        opacity: 4;
        text-align: justify;

        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        //color: #2168DB;
        line-height: 22px;
      }



      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        .grand {
          cursor: pointer;
          width: 100%;
          height:fit-content;
          opacity: 1;
          display: grid;
          grid-template-columns: minmax(0,1fr) ;
          row-gap: 15px;
          .back {
            transition:transform 0.3s ease-out;
            width: 100%;
            position: relative;
            height: 100%;
            background: #bfd8f9;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            .photo {
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              background-color: white; /* 这里设置背景颜色为透明 */
              opacity: 1.25;
              width: 100%;
              margin:auto 0;
              height:auto;
            }


            .title1 {
              padding: 0;
              opacity: 4;
              text-align: center;
              font-size:16px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: bold;
              line-height: 22px;
            }

            .title2 {
              opacity: 4;
              text-align: justify;
              margin:15px auto;;
              justify-content: center;
              align-items: center;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 22px;
            }
            .titleDesign{
              padding: 15px ;
              text-align: justify;
            }
          }
        }
        .back:hover {
          transform: translateY(-10px);
        }
      }
    }
  }

}
</style>
