<template>
<div>
  <div class="Message wrapper" style="height: fit-content;">
    <div class="mainContent">
      <div style="width: 100vw; height: 320px">
        <img loading="lazy" src="../assets/bgmodel.png" />
      </div>
      <div
          v-if="userId"
          style="
              z-index: 9999999;
              position: absolute;
              width: 90vw;
              top: 20px;
              margin: 0 5vw;
              border-radius: 8px;
              height: fit-content;
              padding: 20px;
              display: flex;
              background-color: white;
            "
      >
        <div class="el-dropdown-link">
          <el-avatar
              :size="58"
              :src="reallyavatar ? baseURL + reallyavatar : avator"
          ></el-avatar>
        </div>
        <div style="margin-left: 20px; font-size: 16px; margin-top: 8px">
          你好，{{ nickName }}<br>
          <span style="line-height:28px;margin-top: 15px;font-size: 14px">欢迎来到地质工程云平台，祝您体验愉快！</span>
        </div>

      </div>
      <div
          v-else
          style="
              z-index: 9999999;
              position: absolute;
              width: 90vw;
              top: 50px;
              margin: 0 5vw;
              border-radius: 8px;
              height: 100px;
              padding: 20px;
              display: flex;
              background-color: white;
            "
      >
        <div class="el-dropdown-link">
          <el-avatar :size="58" :src="avator"></el-avatar>
        </div>
        <div
            @click="login()"
            style="margin-left: 20px; font-size: 16px; margin-top: 8px"
        >
          登录/注册<br>
          <span style="line-height:28px;margin-top: 15px;font-size: 14px">欢迎来到地质工程云平台，希望能帮到您！</span>

        </div>
      </div>
      <el-container
          class="centerWidth"
          style="
              width: 90vw;
              border-radius: 9px;
              margin: 0 5vw;
              position: absolute;
              top: 150px;
              font-size: 14px;
            "
      >
        <div style="background-color: white;height: 50vh;width: 100%;border-radius: 9px">
          <el-aside width="100%" height="auto">
            <el-menu
                :default-active="activeMessageName"
                class="el-menu-vertical-demo"
            >
              <el-menu-item
                  @click="handle('/userInfoMobile')">
                <div style="display: flex">

                  <div slot="title">
                    <i style="color: #989898" class="el-icon-document"></i>
                    个人信息

                  </div>
                  <div class="itemLogo"></div>
                </div>

              </el-menu-item>
              <el-menu-item
                  @click="handle('/secureMobile')">
                <div style="display: flex">

                  <div slot="title">
                    <i style="color: #989898" class="el-icon-lock"></i>
                    账户安全

                  </div>
                  <div class="itemLogo"></div>
                </div>

              </el-menu-item>
              <el-menu-item
                  v-if="userId"
                  @click="handle('/logout')">
                <div style="display: flex">

                  <div slot="title">
                    <i style="color: #989898" class="el-icon-switch-button"></i>
                    退出

                  </div>

                </div>

              </el-menu-item>
            </el-menu>
          </el-aside>
        </div>

      </el-container>

    </div>
    <Footer v-if="isMobile === false"></Footer>
    <BottomBanner v-if="isMobile === true"></BottomBanner>
  </div>

</div>

</template>

<script>
  // import LoginTip from "@/components/LoginTip.vue";

  import { getAuthorize, logOut, userDetail } from "@/api/user";
  export default {
  name: "User",
  components: { },
  data() {
  return {
  nickName: "",
  Passtotal:0,
  collect:0,
  see:0,
  dialogVisible: false,
    userId:localStorage.getItem("userId"),
  // userId:80,
  baseURL: this.$utils.baseURL,
  userInfo: this.$store.state.user,
  avator: require("@/assets/user.jpg"),
  sliptrue: false,
  isMobile: false,
  languageSelect: "",
  activeCrumb: "个人信息",
  activeCrumbEn: "Personal information",
  activeMessageName: "/Info",
  messageNav: [
{
  url: "/userInfoMobile",
  name: "我的简历",
}, {
  url: "/secureMobile",
  name: "账户安全",

},

  ],
  showItem: false,
  reallyavatar: "",
};
},
  computed: {},
  created() {
  sessionStorage.setItem("home",false)
  if (this.$store.state.userId) {
  this.getUser();
  this.getdata()
  this.getPasstotal()
  this.getsee()
}
  if (localStorage.getItem("language")) {
  this.languageSelect = localStorage.getItem("language");
} else {
  this.languageSelect = "zh";
}
  this.activeMessageName = this.$route.path;
  let that = this;
  that.messageNav.forEach(function (item) {
  if (item.url == that.activeMessageName) {
  that.activeCrumb = item.name;
  that.activeCrumbEn = item.nameEn;
}
});
  if (
  that.$route.path === "/changePhone" ||
  that.$route.path === "/changePassword" ||
  that.$route.path === "/changeEmail"
  ) {
  that.showItem = true;
  that.activeMessageName = "/secure";
  let type = this.$route.query.type;
  let path = this.$route.path;
  if (path == "/changePhone") {
  that.activeCrumb = type == 1 ? "修改手机号" : "绑定手机号";
  that.activeCrumbEn =
  type == 1 ? "Modify mobile phone number" : "Bind mobile phone number";
}
  if (path == "/changePassword") {
  that.activeCrumb = "修改密码";
  that.activeCrumbEn = "Change password";
}
  if (path == "/changeEmail") {
  that.activeCrumb = type == 1 ? "修改邮箱" : "绑定邮箱";
  that.activeCrumbEn = type == 1 ? "Modify email" : "Bind email";
}
} else {
  that.showItem = false;
}
},
  watch: {
  $route() {
  this.activeMessageName = this.$route.path;
  let that = this;
  that.messageNav.forEach(function (item) {
  if (item.url == that.activeMessageName) {
  that.activeCrumb = item.name;
  that.activeCrumbEn = item.nameEn;
}
});
  if (
  that.$route.path === "/changePhone" ||
  that.$route.path === "/changePassword" ||
  that.$route.path === "/changeEmail"
  ) {
  that.showItem = true;
  that.activeMessageName = "/secure";
  let type = that.$route.query.type;
  let path = that.$route.path;
  if (path == "/changePhone") {
  that.activeCrumb = type == 1 ? "修改手机号" : "绑定手机号";
  that.activeCrumbEn =
  type == 1
  ? "Modify mobile phone number"
  : "Bind mobile phone number";
}
  if (path == "/changePassword") {
  that.activeCrumb = "修改密码";
  that.activeCrumbEn = "Change password";
}
  if (path == "/changeEmail") {
  that.activeCrumb = type == 1 ? "修改邮箱" : "绑定邮箱";
  that.activeCrumbEn = type == 1 ? "Modify email" : "Bind email";
}
} else {
  that.showItem = false;
}
},
  "$store.state.user"(newValue) {
  this.userInfo = newValue;
},
  "$store.state.userId"(newValue, oilValue) {
  if (newValue && newValue != oilValue) {
  console.log(newValue, oilValue, "userIf开始");
  this.getUser();
}
},
},
  methods: {
  pass(){
  this.$router.push("/jobCommit1")
},
  coll(){
  this.$router.push("/jobCollect1")
},
  scan(){
  this.$router.push("/jobCommunicate1")

},
  getUser() {
  userDetail(this.userId).then((res) => {
  this.userInfo = res.data;
  localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
  this.$store.state.user = res.data;
  // this.avator = res.data.avatar;
  this.reallyavatar= res.data.avatar
  this.nickName = res.data.gptsUserName;
  this.isUserDataLoaded = true;
});
  // this.$axios
  //   .get("/system/job_user/" + this.userId)
  //   .then((res) => {
  //     localStorage.setItem("userInfo", JSON.stringify(res.data.data));
  //     this.$store.state.user = res.data.data;
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
},
  // 收藏
  getdata(){
  this.$axios.get("/system/position_collect/count/"+this.userId, {
  headers: {
  Authorization: this.token,
},
})
  .then((res) => {
  this.collect=res.data

})
  .catch((error) => {
  console.log(error);
});
},
  // 投递
  getPasstotal(){
  this.$axios
  .get("/system/deliveryStatus/deliverCount", {
  params: {
  userId:this.userId,
},
  headers: {
  Authorization: this.token,
},
})
  .then((res) => {
  this.Passtotal=res.data.data
})
  .catch((error) => {
  console.log(error);
});
},
  // 浏览
  getsee(){
  this.$axios
  .get("/system/brow/count", {
  params: {
  userId:this.userId,
},
  headers: {
  Authorization: this.token,
},
})
  .then((res) => {
  console.log("liulan")
  console.log(res.data)
  this.see=res.data.data
})
  .catch((error) => {
  console.log(error);
});
},
  isObjectEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
},
  hideDialog() {
  this.dialogVisible = false;
},
  login() {
  let href = window.location.href.split("&code")[0];
  getAuthorize(href).then((res) => {
  window.location.replace(res.data.url);
});
  // this.$router.push("/login");
},
  slip() {
  if (this.sliptrue === true) {
  this.sliptrue = false;
} else {
  this.sliptrue = true;
}
},
  handleSelectMenu(url) {
  console.log("url1" + url);
  if (url !== this.$route.path) {
  console.log("url2" + url);
  if (url === "/logout") {
  // 如果点击的是退出登录项
  this.handleLogout(); // 执行退出登录操作
} else {
  // 如果点击的不是退出登录项，则进行路由切换
  this.$router.push({ path: url });
}
}
},
  handleLogout() {
  // 在这里执行退出登录的逻辑
  console.log("执行退出登录逻辑");
  // 例如：调用您之前提供的 outLogin 方法
  this.outLogin();
},
  handle(url) {
  if (Object.keys(this.userInfo).length !== 0) {
  if (url === "/logout") {
  console.log("调用退出登录逻辑");
  this.handleLogout(); // 执行退出登录操作
} else {
  this.$router.push(url);
}
} else {
  this.dialogVisible = true;
}
},
  outLogin() {
  console.log("调用退出登录逻辑");
  logOut()
  .then((res) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("access_token");
  localStorage.removeItem("userId");
  localStorage.removeItem("typeInfo");
  localStorage.removeItem("access_tokenout");
  localStorage.removeItem("Zyaccess_token");
  localStorage.removeItem("idass");
  this.$store.state.access_token = "";
  this.$store.state.idass = "";
  this.$store.state.user = {};
  // deleteUserAgent(localStorage.getItem("murmur"))
  let data = {
  userAgent: localStorage.getItem("murmur"),
};
  this.$axios
  .post(
  "https://www.oilgasinfoai.com/auth/oauth/deleteUserAgent",
  data
  )
  .then(() => {
  localStorage.removeItem("murmur");
  window.location.href = res.data;
});
})
  .catch((error) => {
  console.error("退出登录失败:", error);
  this.$message.error("退出登录失败，请重试"); // 提示用户退出登录失败
  // 可以在此处执行其他的错误处理逻辑，例如重新加载页面或者显示其他提示信息
});
},
},
  mounted() {
  // Check if the device width is less than 768px (example for mobile)
  this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  console.log(this.isMobile === true);
},
};
</script>
<style scoped lang="scss">
.itemLogo {
  background-image: url("../assets/arrows.png");
  width:15px;
  margin-top: 23px;
  background-size:contain;
  background-position:center;
  background-repeat:no-repeat;
  height: 15px;
  position: absolute;
  right:15px;
  color: #6e6e6eff;
  font-size: 25px;
}
.itemLogo1 {
  position: absolute;
  width: 0;
  height: 0;
  top: -5px;
  left: 75vw;
  color: #6e6e6eff;
  font-size: 25px;
}
.personal{
  position: absolute;
  top:120px;
  width: 90%;
  left: 5%;
  border-radius: 9px;
  padding: 20px 10px;
  height: fit-content;
  background-color: white;
  display: grid;
  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
  .my{
    font-size: 14px;
    width: 100%;
    text-align: center;
    .myphoto{
      margin: 10px auto;
      font-size: 18px;
      font-weight: bold;

    }

  }
}
.wrapper{
  min-height: 770px !important;
  height: fit-content;

}
.logout{
  position: absolute;
  top:350px;
  width: 90%;
  left: 5%;
  background: #f5f5f5;

  text-align: center;
  .title{
    background: white;
    border-radius: 9px;
    border: #3163c5 1px solid;
    padding: 15px 20px 15px 20px;
  }
}
.el-aside {
  background: url(../assets/userBg.png) no-repeat right bottom, #fff;
  background-size: 172px 164px;
  padding-bottom:0;
  margin-bottom: 54px;
  text-align: center;
  border-radius: 9px;

  .ViewName {
    height: 64px;
    line-height: 64px;
    color: #333333;
    font-size: 20px;
    margin: 0 16px;
    border-bottom: 1px solid #ebebeb;
  }

  .el-menu {
    background: none !important;
    font-size: 16px;
    color: #333333;
    border: none !important;
    //margin-top: 19px;
    padding: 0 15px;
    text-align: center;

    .el-menu-item,
    .el-submenu__title {
      font-size: 16px;
      border-bottom: rgba(128, 128, 128, 0.1) 1px solid;
      height: 64px;
      line-height: 64px;

      span {
        position: relative;

        .el-badge {
          position: absolute;
          left: calc(100% + 12px);
        }
      }
    }

    .el-menu-item.is-active {
      background: rgba(191, 0, 34, 0.1);
      color: var(--main-color);
      position: relative;
    }

    .el-menu-item.is-active::before {
      content: " ";
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      position: absolute;
      background: var(--main-color);
    }

    .el-menu-item:focus,
    .el-menu-item:hover {
      background: rgba(191, 0, 34, 0.1);
      color: var(--main-color);
      font-weight: 500;
    }
  }
}

.el-main {
  padding: 0;
  margin-left: 10px;
}
</style>
