var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.model===false)?_c('div',[_c('div',{staticClass:"totalmakeHole"},[_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"screen1"},[_c('headtip',{on:{"showDialog":_vm.showPartner}}),_c('div',{staticClass:"layer2"},[_c('div',{staticClass:"title1"},[_vm._v("非常规油气藏全尺度缝控压裂技术服务 ")]),_c('div',{staticClass:"title2"},[_vm._v("全尺度缝控压裂技术应用在非常规油气藏压裂改造（页岩、砂岩、深层煤层气等）中，是由构建多尺度裂缝和全尺度裂缝充填两个设计理念构成，通过提升改造后的波及体积和裂缝有效导流能力，提高压后初期产量并延长稳产期，最终实现最优化的累计产量。")]),_vm._v(" "),_c('div',{staticClass:"titlle3",on:{"click":function($event){return _vm.clickConsult('地质工程云平台')}}},[_vm._v(" 产品咨询 ")])]),_c('div',{staticClass:"layer3"})],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('bottomTip')],1),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)]):_c('div',[_c('div',{staticClass:"totalmakeHoleModel"},[_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"screen1"},[_c('div',{staticStyle:{"margin":"0 15px"}},[_c('headtip',{on:{"showDialog":_vm.showPartner}})],1),_vm._m(6),_c('div',{staticClass:"titlle3",on:{"click":function($event){return _vm.clickConsult('地质工程云平台')}}},[_vm._v(" 产品咨询 ")]),_c('div',{staticClass:"layer3"})]),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('div',{ref:"bottom"},[_c('bottomTip')],1)]),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("技术思路 ")]),_c('div',{staticClass:"heightLight2"},[_vm._v("以提升压裂改造后的波及体积和裂缝有效导流能力，提出全尺度缝控压裂技术，通过构建多尺度裂缝、全尺度裂缝充分实现裂缝三维全支撑，最终实现最优化的累计产量。 ")]),_c('div',{staticStyle:{"width":"100%","height":"fit-content","text-align":"center"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"80%"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture1.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0",staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"heighLight"},[_vm._v("核心技术 ")]),_c('div',{staticClass:"lr-box"},[_c('div',{staticStyle:{"height":"fit-content"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"100%"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture2.png")}})]),_c('div',[_c('h4',[_vm._v("① 精细地质研究 + 地质工程一体化压裂设计：")]),_c('p',[_vm._v("通过建立属性、天然裂缝、地应力三维精细综合模型，为精细化设计提供依据。")]),_c('p',[_vm._v("地质与工程相结合，通过精细三维地质及地质力学建模，准确认识砂体展布开展个性化、精细化布缝设计；储层渗流能力与裂缝导流能力匹配，精确设计施工规模及参数，实现“一段一策”的精细化压裂设计。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"lr-box rl"},[_c('div',[_c('h4',[_vm._v("② 聚合物干粉变粘压裂液+纳米防水锁功能液技术体系：")]),_c('p',[_vm._v("通过常规聚合物干粉改性，使其溶解性能和抗盐性能极大提高，同时研制出配套的高效连续混配设备，实现施工现场的压裂液即供、即配、即注，达到提高降本、增效、环保效果。")]),_c('p',[_vm._v("采用纳米表面活性剂，利用材料的独特空间位阻效应，减少吸附的同时有效改变岩石润湿性，增大接触角，作用于地层，实现助排、防水锁功效，达到增产目的。")])]),_c('div',{staticStyle:{"height":"fit-content"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"100%"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture3.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0",staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"lr-box"},[_c('div',{staticStyle:{"height":"fit-content"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"100%","height":"40vh"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture4.png")}})]),_c('div',[_c('h4',[_vm._v("③ 多级粒径支撑剂+自悬浮支撑剂+固结砂防返吐技术体系：")]),_c('p',[_vm._v("优选多级粒径支撑剂组合，适时支撑及多级粒径支撑剂匹配设计采取不同粒径比例、加入时机及加入方式。")]),_c('p',[_vm._v("自悬浮支撑剂进行水溶性涂覆层后，分散到液体中后具有自悬浮功能，能够在不增粘、不增加排量的情况下改善铺砂剖面，降低施工压力，提高裂缝的充填效果，实现全尺度裂缝支撑，最终提高单井产量。")]),_c('p',[_vm._v("柔性固结砂在一定条件下具有自粘结典型特征，且透油、透气、透水的一种压裂支撑剂产品，通过尾追一部分柔性固结砂，可以达到防止地层出砂、防止支撑剂回流的目的。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"lr-box rl"},[_c('div',[_c('h4',[_vm._v("④实时迭代压裂技术+可视化监测技术：")]),_c('p',[_vm._v("地质工程一体化实时迭代分析（压裂曲线实时诊断、地质模型更新迭代等），实现裂缝扩展实时评估及地质再认识。")]),_c('p',[_vm._v("结合广域电磁监测开展方案优化及调整：通过监测压裂液入地后产生的电性变化引起的电磁响应，获取电磁时间域差分异常，反映压裂液波及范围，进而分析缝网特征。")])]),_c('div',{staticStyle:{"height":"fit-content"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"100%"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture5.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0",staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"lr-box"},[_c('div',{staticStyle:{"height":"fit-content"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"100%","height":"40vh","object-fit":"contain","background-color":"#fff"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture6.png")}})]),_c('div',[_c('h4',[_vm._v("⑤可溶性绳结暂堵转向技术：")]),_c('p',[_vm._v("可溶绳结暂堵塞通过异形可溶性暂堵塞封堵炮眼或近井裂缝缝口，可更有效实现段内裂缝分流转向，提高不同物性、地应力层/簇均衡改造程度，达到裂缝均衡扩展、进一步提产的效果。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layer2"},[_c('div',{staticClass:"title1"},[_vm._v("非常规油气藏全尺度缝控压裂技术服务 ")]),_c('div',{staticClass:"title2"},[_vm._v("全尺度缝控压裂技术应用在非常规油气藏压裂改造（页岩、砂岩、深层煤层气等）中，是由构建多尺度裂缝和全尺度裂缝充填两个设计理念构成，通过提升改造后的波及体积和裂缝有效导流能力，提高压后初期产量并延长稳产期，最终实现最优化的累计产量。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("技术思路 ")]),_c('div',{staticClass:"heightLight2"},[_vm._v("以提升压裂改造后的波及体积和裂缝有效导流能力，提出全尺度缝控压裂技术，通过构建多尺度裂缝、全尺度裂缝充分实现裂缝三维全支撑，最终实现最优化的累计产量。 ")]),_c('div',{staticStyle:{"width":"100%","height":"fit-content","text-align":"center"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"80%"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture1.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0",staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"heighLight"},[_vm._v("核心技术 ")]),_c('div',{staticClass:"grid"},[_c('div',{staticStyle:{"height":"fit-content"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"100%"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture2.png")}})]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"title1"},[_vm._v("① 精细地质研究 + 地质工程一体化压裂设计：")]),_c('div',{staticClass:"title2"},[_vm._v("通过建立属性、天然裂缝、地应力三维精细综合模型，为精细化设计提供依据。")]),_c('div',{staticClass:"title2"},[_vm._v("地质与工程相结合，通过精细三维地质及地质力学建模，准确认识砂体展布开展个性化、精细化布缝设计；储层渗流能力与裂缝导流能力匹配，精确设计施工规模及参数，实现“一段一策”的精细化压裂设计。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"grid"},[_c('div',{staticStyle:{"height":"fit-content"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"100%"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture3.png")}})]),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("② 聚合物干粉变粘压裂液+纳米防水锁功能液技术体系：")]),_c('div',{staticClass:"title2"},[_vm._v("通过常规聚合物干粉改性，使其溶解性能和抗盐性能极大提高，同时研制出配套的高效连续混配设备，实现施工现场的压裂液即供、即配、即注，达到提高降本、增效、环保效果。")]),_c('div',{staticClass:"title2"},[_vm._v("采用纳米表面活性剂，利用材料的独特空间位阻效应，减少吸附的同时有效改变岩石润湿性，增大接触角，作用于地层，实现助排、防水锁功效，达到增产目的。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0",staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"grid"},[_c('div',{staticStyle:{"height":"fit-content"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"100%"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture4.png")}})]),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("③ 多级粒径支撑剂+自悬浮支撑剂+固结砂防返吐技术体系：")]),_c('div',{staticClass:"title2"},[_vm._v("优选多级粒径支撑剂组合，适时支撑及多级粒径支撑剂匹配设计采取不同粒径比例、加入时机及加入方式。")]),_c('div',{staticClass:"title2"},[_vm._v("自悬浮支撑剂进行水溶性涂覆层后，分散到液体中后具有自悬浮功能，能够在不增粘、不增加排量的情况下改善铺砂剖面，降低施工压力，提高裂缝的充填效果，实现全尺度裂缝支撑，最终提高单井产量。")]),_c('div',{staticClass:"title2"},[_vm._v("柔性固结砂在一定条件下具有自粘结典型特征，且透油、透气、透水的一种压裂支撑剂产品，通过尾追一部分柔性固结砂，可以达到防止地层出砂、防止支撑剂回流的目的。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"grid"},[_c('div',{staticStyle:{"height":"fit-content"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"100%"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture5.png")}})]),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("④实时迭代压裂技术+可视化监测技术：")]),_c('div',{staticClass:"title2"},[_vm._v("地质工程一体化实时迭代分析（压裂曲线实时诊断、地质模型更新迭代等），实现裂缝扩展实时评估及地质再认识。")]),_c('div',{staticClass:"title2"},[_vm._v("结合广域电磁监测开展方案优化及调整：通过监测压裂液入地后产生的电性变化引起的电磁响应，获取电磁时间域差分异常，反映压裂液波及范围，进而分析缝网特征。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0",staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"grid"},[_c('div',{staticStyle:{"height":"fit-content"}},[_c('img',{staticStyle:{"border-radius":"9px","width":"100%","object-fit":"contain"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/fracture6.png")}})]),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("⑤可溶性绳结暂堵转向技术：")]),_c('div',{staticClass:"title2"},[_vm._v("可溶绳结暂堵塞通过异形可溶性暂堵塞封堵炮眼或近井裂缝缝口，可更有效实现段内裂缝分流转向，提高不同物性、地应力层/簇均衡改造程度，达到裂缝均衡扩展、进一步提产的效果。")])])])])
}]

export { render, staticRenderFns }