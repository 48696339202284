<template>
<div>
  <div ref="total" class="total">
    <div class="screen1">
      <headtip @showDialog="showPartner"></headtip>
      <div class="mainContent" >
        <el-container class="centerWidth">
          <div style="overflow-y: hidden;text-align: center;height: 75vh;border-radius: 9px;background-color: white;padding-top: 3vh">
            <el-aside width="15vw" style="overflow-y: hidden;text-align: center;height: 70vh;">
              <el-menu
                  :default-active="activeMessageName"
                  class="el-menu-vertical-demo"
              >
                <el-menu-item
                    v-for="(item, index) in messageNav"
                    :key="index"
                    :index="item.url"
                    @click.native="handleSelectMenu(item.url)"
                >
                  <span slot="title">
                    {{ item.name }}
                    <!--                    <el-badge-->
                    <!--                        v-if="item.url == '/receivedlikes' && unreadMessage"-->
                    <!--                        class="mark"-->
                    <!--                        :value="unreadMessage"-->
                    <!--                        :max="99"-->
                    <!--                    />-->
                  </span>
                </el-menu-item>
              </el-menu>
            </el-aside>

          </div>
           <el-main >
            <router-view />
          </el-main>
        </el-container>
      </div>
    </div>

  </div>
  <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>


</div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import PartnerTip from "@/components/partnerTip.vue";

export default {

  name: "",

  props: [],

  components: {PartnerTip, headtip},

  data() {

    return {
      dialogconsult: false,
      showItem: false,
      activeCrumb: "个人信息",
      activeMessageName: "/userInformation",
      messageNav: [
        {
          url: "/userInformation",
          name: "个人信息",
        },
        {
          url: "/secure",
          name: "账户安全",
        },
      ],
    }
  },

  methods: {
    showPartner() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },
    hide() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = false;
    },
    handleSelectMenu(url) {
      console.log("url1" + url);
      if (url !== this.$route.path) {
        console.log("url2" + url);
        if (url === "/logout") {
          // 如果点击的是退出登录项
          this.handleLogout(); // 执行退出登录操作
        } else {
          // 如果点击的不是退出登录项，则进行路由切换
          this.$router.push({ path: url });
        }
      }
    },
  },

  mounted() {


  }

}

</script>


<style type="text/scss" lang="scss" scoped>

.total {
  //background: #bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
    background-image: url("../assets/back1.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .mainContent{
      width: 81vw;
      border-radius: 9px;
      margin:5vh auto;
      //background-color: white;
      height: 80vh;
      padding: 3vh 2vw 3vh 0;
    }
    ::v-deep .el-menu-item.is-active{
      background-color: #cce0fb;
      color: #316fff;
      //font-weight: bold;
      font-size: 16px;

      //border-radius: 9px !important;
    }
    ::v-deep .el-menu-item{
      //background-color: #cce0fb;
      //color: #316fff;
      //font-weight: bold;
      font-size: 16px;

      //border-radius: 9px !important;
    }
  }
}

</style>
