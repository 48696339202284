<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">地质工程相结合老井挖潜系列技术
              </div>
              <div class="title2">地质工程相结合老井挖潜技术在精细地质研究的基础上，通过物理、化学、生物技术/材料为手段，一方面通过解除油气水流动阻力，增加地层流体的流动性；另一方面通过“堵”、“调”优化原油流动剖面，从而提高井控范围内油气动用程度，充分挖掘老井生产潜力。</div>
              <div class="titlle3" @click="clickConsult('地质工程云平台')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenGpts" style="background-color: white"   ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;堵水调剖调驱技术
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2" @click="rhmodie">
                <div class="back "  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect1.png"/>
                  </div>
                  <div class="title1">RH超分子多功能驱油技术

                  </div>
<!--                  <div class="title2">RH超分子多功能驱油剂是针对聚合物驱、二元驱及三元复合驱存在的问题而研发出来的，具有耐温、耐盐、耐机械剪切等特点，通过自身的分子结构作用能够实现油藏深度调驱，是一种高效油藏深度调驱的技术。具有增粘性能强、粘度稳定性好、抗盐性能强、乳化增溶性能好的特点。-->

<!--                  </div>-->
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="temp">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect2.png"/></div>
                  <div class="title1">抗高温高强度复合调堵技术
                  </div>
<!--                  <div class="title2">抗高温高强度复合调堵剂是采用无机材料作为主剂，增粘剂作为体系稳定剂，提高体系的稳定性和可泵送性；外加缓凝剂，配制成溶液注入地层；利用地层热能，溶液在地层固化，形成高强度的抗高温混凝土状的固态物质，在窜流、出水通道形成有效封堵，降低油井含水，提高稠油油田最终采收率。-->
<!--                  </div>-->

                </div>
              </div>
              <div class="grand2" @click="necleus">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect3.png"/></div>
                  <div class="title1">核壳异性水基微球调驱技术
                  </div>
<!--                  <div class="title2">核壳异性水基微球是针对低渗孔隙型或孔隙-裂缝型油藏调驱存在的问题而研发出来的。具有外壳包覆内核且外壳亲水、内核粒径纳米且油溶水不溶等特点。微球深部注入性好，且外壳在注入过程，受剪切等作用，外壳溶解、释放内核，且内核进入水相时聚并封堵，内核进入油相时分散原油（洗油），具有“遇水堵水、遇油洗油”智能调驱作用。-->
<!--                  </div>-->
                </div>
              </div>
              <div class="grand2" @click="water">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect4.png"/></div>
                  <div class="title1">自聚集水基微球调驱技术
                  </div>
<!--                  <div class="title2">自聚集水基微球是针对裂缝型或裂缝-孔隙型油藏调驱存在的问题而研发出来的。具有修饰改性亲水性外壳+内核+乳化表活剂结构特征，通过可控自聚集封堵机理+原油就地乳化形成油墙辅助调驱机理，实现自聚集微球对高渗裂缝选择性封堵、乳化表活剂乳化-乳状液团聚-封堵-液体解聚-破乳小液滴-增溶洗油辅助驱油效果。 </div>-->
                </div>
              </div>
              <div class="grand2" @click="bottom">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect5.png"/></div>
                  <div class="title1">边底水油藏选择性堵水技术
                  </div>
<!--                  <div class="title2">针对因生产参数过大或进攻性改造措施不当导致的底水上窜或底水锥进油井，采用选择性堵剂、原采油射孔段挤注堵剂工艺封堵上窜或锥进底水；针对油层较薄（＜5m）、油水界面清晰、因油水界面上移导致底水整体抬升、锥进的油井，通过数模研究等手段确定新油水界面、在新油水界面上部适当位置补射新孔且从新孔处挤注选择性堵剂形成人工隔板。从而降低油井产液含水、提高产油量。  </div>-->
                </div>
              </div>
            </div>

          </div>
          <div class="screenGpts"    ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;油气水井解堵技术
                </div>
              </div>
            </div>

            <div class="pro3">
              <!--油田-->
              <div class="grand2" @click="enzyme">
                <div class="back" >
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect6.png"/>
                  </div>
                  <div class="title1">石油酶素解堵技术
                  </div>
<!--                  <div class="title2">石油酶素是一类具有催化作用的生物基材料，可显著恢复地层原油的流动能力。它是运用基因工程、细胞工程、酶工程等现代生物工程技术，诱变和选育新菌种研发的国际领先的成熟产品，具有高效释放固体粒子表面原油的能力。具有催化洗油活性、改变岩石润湿性、降低界面张力的特点。-->
<!--                  </div>-->
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="liquid">
                <div class="back">
                  <div class="photo"><img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect7.png"/></div>
                  <div class="title1">液流空化增产增注技术


                  </div>
<!--                  <div class="title2">液流空化储层改造技术是采用油管连接下入空化器，通过高速液流循环激发空化器产生空化激波、自振波及声波等压力波共同作用于储层及储层内流体，激发储层孔隙内液体产生次级空化，利用空化溃灭瞬间产生高能量作用于孔隙、喉道，贯通和制造大量微裂缝，新增疲劳裂缝，改善储层渗透率，实现增产增注目的。-->
<!--                  </div>-->
                </div>
              </div>
              <!--油田-->
              <div class="grand2" @click="gaswell">
                <div class="back" >
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect8.png"/>
                  </div>
                  <div class="title1">气井解堵（解水锁）技术


                  </div>
<!--                  <div class="title2">气井解堵技术是向井内加注解水锁剂，具有降低地层水与岩石表面界面张力作用，对无机及有机垢物具有一定溶蚀能力，油管加注解水锁剂后关井，依靠外来气源将药剂憋入地层，药剂在孔道表面形成低界面张力膜，并降低地层水表面张力，降低最低流动压差及激动压差。通过放空或者直接将液体输入气站，排出井区附近积液，维持一段时间气液良好通过能力。-->
<!--                </div>-->
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="magnet">
                <div class="back">
                  <div class="photo"><img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect9.png"/></div>
                  <div class="title1">巨磁降粘防蜡技术


                  </div>
<!--                  <div class="title2">在强磁场作用下，磁场能量交换，为介质流动过程中的剪切力变化作用结果，磁场强度越大，其转换磁场能量越多，油流介质表面应力张量越大，形成的剪切应力张量越大，原油粘度下降越多。 纳米成型铸造微晶巨磁合金组合强度，将蜡分子磁化、与原油形成交替形态，使流动原油携蜡、不形成蜡层、不吸附管壁。 整体割缝式双层组合滤砂结构，滤层为1Cr18Ni9Ti材质，经特殊工艺压制，滤砂粒径可调，内侧分布微晶巨磁合金-吸附磁性杂质防止吸附阀组导致漏失。-->
<!--                  </div>-->
                </div>
              </div>
              <!--油田-->
              <div class="grand2" @click="pressure">
                <div class="back" >
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect10.png"/>
                  </div>
                  <div class="title1">高压欠注井降压增注技术


                  </div>
<!--                  <div class="title2">砂岩油藏注水井生产过程，由于水化学堵、腐蚀结垢堵、注水水质堵、粘土膨胀堵、矿物运移堵、碳酸盐胶结物堵及储层内部界面张力、润湿性、低孔低渗等因素导致的物性堵，是造成注水井高压欠注的综合原因。-->
<!--                </div>-->
                </div>
              </div>

            </div>

          </div>
          <div class="screenGpts" style="background-color: white"   ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;提高采收率技术
                </div>
              </div>
            </div>
            <div class="pro3" style="grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)">

              <!--油田-->
              <div class="grand2" @click="microorganism">
                <div class="back "  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect11.png"/>
                  </div>
                  <div class="title1">微生物采油技术

                  </div>
<!--                  <div class="title2">微生物采油技术，是指利用特定功能菌体在储层，以原油为碳源代谢生长，过程中产生增殖量的菌体，同时产生生物表面活性剂、有机酸、气体、酶类等多种活性物质，作用于储层，改善原油的流动能力，调整储层润湿性，降解胶质沥青质等，作用于油藏残余油。能让储层趋向原油生长增殖性，极大延缓作用期、改变岩石润湿性、降低界面张力。-->

<!--                  </div>-->
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="gas">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect12.png"/></div>
                  <div class="title1">高温混合气体吞吐注采技术


                  </div>
<!--                  <div class="title2">将空气中的氧气作为氧化剂，以柴油为燃烧剂，在高压下进行爆燃，产生高温高压的二氧化碳和氮气气体。再通过水与之掺混进行热交换，水吸热汽化形成水蒸气，二氧化碳和氮气放热降温至200-250度。最后利用其高压将高温混合气注入油层。-->

<!--                  </div>-->
                </div>
              </div>
              <div class="grand2" @click="heavyoil">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect13.png"/></div>
                  <div class="title1">超稠油井低温乳化降粘技术

                  </div>
<!--                  <div class="title2">乳化降粘技术是利用乳化剂及其氧化的过程，分散稠油中的固体和液体成份；通过在乳化剂和稠油中引入低分子量的表面活性剂，形成稠油-乳化剂-表面活性剂三元体系，从而使稠油溶解在乳化剂中，改变粘度。-->

<!--                </div>-->
                </div>
              </div>
              <div class="grand2" @click="dense">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect14.png"/></div>
                  <div class="title1">纳米渗吸剂提高采收率技术

                  </div>
<!--                  <div class="title2">超低渗、页岩/致密油藏物性差，一般为细孔微细/极细喉储层，水很难进入纳米孔喉，波及体积小，水驱采收率低。纳米渗吸表活剂粒径小（纳米级），注入储层后，可使地层原油（尤其是残余油）通过毛管力，自发从岩石上乳化、剥离、置换，形成水包油乳化混相液，流体粘度降低，流动阻力变小，从而提高驱油效率。-->

<!--                </div>-->
                </div>
              </div>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
              <headtip @showDialog="showPartner"></headtip>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">地质工程相结合老井挖潜系列技术
              </div>
              <div class="title2">地质工程相结合老井挖潜技术在精细地质研究的基础上，通过物理、化学、生物技术/材料为手段，一方面通过解除油气水流动阻力，增加地层流体的流动性；另一方面通过“堵”、“调”优化原油流动剖面，从而提高井控范围内油气动用程度，充分挖掘老井生产潜力。</div>
            </div>
            <div class="titlle3" @click="clickConsult('地质工程云平台')">
              产品咨询
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenGpts" style="background-color: white"   ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;堵水调剖调驱技术
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2" @click="rhmodie">
                <div class="back "  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect1.png"/>
                  </div>
                  <div class="title1">RH超分子多功能驱油技术

                  </div>
                  <!--                  <div class="title2">RH超分子多功能驱油剂是针对聚合物驱、二元驱及三元复合驱存在的问题而研发出来的，具有耐温、耐盐、耐机械剪切等特点，通过自身的分子结构作用能够实现油藏深度调驱，是一种高效油藏深度调驱的技术。具有增粘性能强、粘度稳定性好、抗盐性能强、乳化增溶性能好的特点。-->

                  <!--                  </div>-->
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="temp">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect2.png"/></div>
                  <div class="title1">抗高温高强度复合调堵技术
                  </div>
                  <!--                  <div class="title2">抗高温高强度复合调堵剂是采用无机材料作为主剂，增粘剂作为体系稳定剂，提高体系的稳定性和可泵送性；外加缓凝剂，配制成溶液注入地层；利用地层热能，溶液在地层固化，形成高强度的抗高温混凝土状的固态物质，在窜流、出水通道形成有效封堵，降低油井含水，提高稠油油田最终采收率。-->
                  <!--                  </div>-->

                </div>
              </div>
              <div class="grand2" @click="necleus">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect3.png"/></div>
                  <div class="title1">核壳异性水基微球调驱技术
                  </div>
                  <!--                  <div class="title2">核壳异性水基微球是针对低渗孔隙型或孔隙-裂缝型油藏调驱存在的问题而研发出来的。具有外壳包覆内核且外壳亲水、内核粒径纳米且油溶水不溶等特点。微球深部注入性好，且外壳在注入过程，受剪切等作用，外壳溶解、释放内核，且内核进入水相时聚并封堵，内核进入油相时分散原油（洗油），具有“遇水堵水、遇油洗油”智能调驱作用。-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="grand2" @click="water">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect4.png"/></div>
                  <div class="title1">自聚集水基微球调驱技术
                  </div>
                  <!--                  <div class="title2">自聚集水基微球是针对裂缝型或裂缝-孔隙型油藏调驱存在的问题而研发出来的。具有修饰改性亲水性外壳+内核+乳化表活剂结构特征，通过可控自聚集封堵机理+原油就地乳化形成油墙辅助调驱机理，实现自聚集微球对高渗裂缝选择性封堵、乳化表活剂乳化-乳状液团聚-封堵-液体解聚-破乳小液滴-增溶洗油辅助驱油效果。 </div>-->
                </div>
              </div>
              <div class="grand2" @click="bottom">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect5.png"/></div>
                  <div class="title1">边底水油藏选择性堵水技术
                  </div>
                  <!--                  <div class="title2">针对因生产参数过大或进攻性改造措施不当导致的底水上窜或底水锥进油井，采用选择性堵剂、原采油射孔段挤注堵剂工艺封堵上窜或锥进底水；针对油层较薄（＜5m）、油水界面清晰、因油水界面上移导致底水整体抬升、锥进的油井，通过数模研究等手段确定新油水界面、在新油水界面上部适当位置补射新孔且从新孔处挤注选择性堵剂形成人工隔板。从而降低油井产液含水、提高产油量。  </div>-->
                </div>
              </div>
            </div>

          </div>
          <div class="screenGpts"    ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;油气水井解堵技术
                </div>
              </div>
            </div>

            <div class="pro3">
              <!--油田-->
              <div class="grand2" @click="enzyme">
                <div class="back" style="background-color: white">
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect6.png"/>
                  </div>
                  <div class="title1">石油酶素解堵技术
                  </div>
                  <!--                  <div class="title2">石油酶素是一类具有催化作用的生物基材料，可显著恢复地层原油的流动能力。它是运用基因工程、细胞工程、酶工程等现代生物工程技术，诱变和选育新菌种研发的国际领先的成熟产品，具有高效释放固体粒子表面原油的能力。具有催化洗油活性、改变岩石润湿性、降低界面张力的特点。-->
                  <!--                  </div>-->
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="liquid">
                <div class="back"  style="background-color: white">
                  <div class="photo"><img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect7.png"/></div>
                  <div class="title1">液流空化增产增注技术


                  </div>
                  <!--                  <div class="title2">液流空化储层改造技术是采用油管连接下入空化器，通过高速液流循环激发空化器产生空化激波、自振波及声波等压力波共同作用于储层及储层内流体，激发储层孔隙内液体产生次级空化，利用空化溃灭瞬间产生高能量作用于孔隙、喉道，贯通和制造大量微裂缝，新增疲劳裂缝，改善储层渗透率，实现增产增注目的。-->
                  <!--                  </div>-->
                </div>
              </div>
              <!--油田-->
              <div class="grand2" @click="gaswell">
                <div class="back"  style="background-color: white">
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect8.png"/>
                  </div>
                  <div class="title1">气井解堵（解水锁）技术


                  </div>
                  <!--                  <div class="title2">气井解堵技术是向井内加注解水锁剂，具有降低地层水与岩石表面界面张力作用，对无机及有机垢物具有一定溶蚀能力，油管加注解水锁剂后关井，依靠外来气源将药剂憋入地层，药剂在孔道表面形成低界面张力膜，并降低地层水表面张力，降低最低流动压差及激动压差。通过放空或者直接将液体输入气站，排出井区附近积液，维持一段时间气液良好通过能力。-->
                  <!--                </div>-->
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="magnet">
                <div class="back"  style="background-color: white">
                  <div class="photo"><img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect9.png"/></div>
                  <div class="title1">巨磁降粘防蜡技术


                  </div>
                  <!--                  <div class="title2">在强磁场作用下，磁场能量交换，为介质流动过程中的剪切力变化作用结果，磁场强度越大，其转换磁场能量越多，油流介质表面应力张量越大，形成的剪切应力张量越大，原油粘度下降越多。 纳米成型铸造微晶巨磁合金组合强度，将蜡分子磁化、与原油形成交替形态，使流动原油携蜡、不形成蜡层、不吸附管壁。 整体割缝式双层组合滤砂结构，滤层为1Cr18Ni9Ti材质，经特殊工艺压制，滤砂粒径可调，内侧分布微晶巨磁合金-吸附磁性杂质防止吸附阀组导致漏失。-->
                  <!--                  </div>-->
                </div>
              </div>
              <!--油田-->
              <div class="grand2" @click="pressure">
                <div class="back"  style="background-color: white">
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect10.png"/>
                  </div>
                  <div class="title1">高压欠注井降压增注技术


                  </div>
                  <!--                  <div class="title2">砂岩油藏注水井生产过程，由于水化学堵、腐蚀结垢堵、注水水质堵、粘土膨胀堵、矿物运移堵、碳酸盐胶结物堵及储层内部界面张力、润湿性、低孔低渗等因素导致的物性堵，是造成注水井高压欠注的综合原因。-->
                  <!--                </div>-->
                </div>
              </div>

            </div>

          </div>
          <div class="screenGpts" style="background-color: white"   ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;提高采收率技术
                </div>
              </div>
            </div>
            <div class="pro3" >
              <!--油田-->
              <div class="grand2" @click="microorganism">
                <div class="back "  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect11.png"/>
                  </div>
                  <div class="title1">微生物采油技术

                  </div>
                  <!--                  <div class="title2">微生物采油技术，是指利用特定功能菌体在储层，以原油为碳源代谢生长，过程中产生增殖量的菌体，同时产生生物表面活性剂、有机酸、气体、酶类等多种活性物质，作用于储层，改善原油的流动能力，调整储层润湿性，降解胶质沥青质等，作用于油藏残余油。能让储层趋向原油生长增殖性，极大延缓作用期、改变岩石润湿性、降低界面张力。-->

                  <!--                  </div>-->
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="gas">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect12.png"/></div>
                  <div class="title1">高温混合气体吞吐注采技术


                  </div>
                  <!--                  <div class="title2">将空气中的氧气作为氧化剂，以柴油为燃烧剂，在高压下进行爆燃，产生高温高压的二氧化碳和氮气气体。再通过水与之掺混进行热交换，水吸热汽化形成水蒸气，二氧化碳和氮气放热降温至200-250度。最后利用其高压将高温混合气注入油层。-->

                  <!--                  </div>-->
                </div>
              </div>
              <div class="grand2" @click="heavyoil">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect13.png"/></div>
                  <div class="title1">超稠油井低温乳化降粘技术

                  </div>
                  <!--                  <div class="title2">乳化降粘技术是利用乳化剂及其氧化的过程，分散稠油中的固体和液体成份；通过在乳化剂和稠油中引入低分子量的表面活性剂，形成稠油-乳化剂-表面活性剂三元体系，从而使稠油溶解在乳化剂中，改变粘度。-->

                  <!--                </div>-->
                </div>
              </div>
              <div class="grand2" @click="dense">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect14.png"/></div>
                  <div class="title1">纳米渗吸剂提高采收率技术

                  </div>
                  <!--                  <div class="title2">超低渗、页岩/致密油藏物性差，一般为细孔微细/极细喉储层，水很难进入纳米孔喉，波及体积小，水驱采收率低。纳米渗吸表活剂粒径小（纳米级），注入储层后，可使地层原油（尤其是残余油）通过毛管力，自发从岩石上乳化、剥离、置换，形成水包油乳化混相液，流体粘度降低，流动阻力变小，从而提高驱油效率。-->

                  <!--                </div>-->
                </div>
              </div>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import temperature from "@/views/groundthrid/temperature.vue";
export default {

  name: "",
  computed: {
    temperature() {
      return temperature
    }
  },

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {
      title:'',
      pageTitle: '地质工程相结合老井挖潜系列技术-工程地质技术GPTs-地质工程云平台GPT',
      pageDescription: '地质工程相结合老井挖潜技术在精细地质研究的基础上，通过物理、化学、生物技术/材料为手段，一方面通过解除油气水流动阻力，增加地层流体的流动性；另一方面通过“堵”、“调”优化原油流动剖面，从而提高井控范围内油气动用程度，充分挖掘老井生产潜力。',
      pageKeywords: '地质工程相结合老井挖潜系列技术,工程地质技术GPTs,地质工程云平台GPT',
      model:false,
      dialogconsult:false,
      dialogVisible:false,

    }

  },


  methods: {
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    rhmodie(){
      window.open("/RHsupramolecular")
    },
    temp(){
      window.open("/emperatureresistance")
    },
    necleus(){
      window.open("/core-shell")
    },
    water(){
      window.open("/self-assembling")
    },
    bottom(){
      window.open("/waterreservoir")
    },
    microorganism(){
      window.open("/microbialoil")
    },
    gas(){
      window.open("/hightemperature")
    },
    heavyoil(){
      window.open("/emulsification")
    },
    dense(){
      window.open("/nanoimbibing")
    },
    enzyme(){
      window.open("/petroleumenzyme")
    },
    liquid(){
      window.open("/fluidcavitation")
    },
    gaswell(){
      window.open("/waterlockremoval")
    },
    magnet(){
      window.open("/giantmagnetic")
    },
    pressure(){
      window.open("/pressurereduction")
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '地质工程相结合老井挖潜系列技术-工程地质技术GPTs-地质工程云平台GPT',
        pageDescription: '地质工程相结合老井挖潜技术在精细地质研究的基础上，通过物理、化学、生物技术/材料为手段，一方面通过解除油气水流动阻力，增加地层流体的流动性；另一方面通过“堵”、“调”优化原油流动剖面，从而提高井控范围内油气动用程度，充分挖掘老井生产潜力。',
        pageKeywords: '地质工程相结合老井挖潜系列技术,工程地质技术GPTs,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 2.84301606922126vh 1.5vw  2.84301606922126vh 5vw;
      background-image: url("../../assets/connectbanner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }
        .titlle3{
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        display: grid;
        grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        row-gap: 2vh;
        .grand2 {
          cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 20vh;
          }


          .title1 {
            padding: 4vh 0;
            opacity: 4;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.71940667490729vh;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 3.5vh;
          }

          .title2 {
            opacity: 4;
            margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
            text-align: justify;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            height: 13.5vh;

          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 86%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:58vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin:auto;
              color: white;
              width: 35%;
              float: right;
              .title1{
                font-size: 1.1709416522vw;
              }
              .title2{
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:59.87268232385661vh !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}

.totalmakeHoleModel{
  background: #d4e4ff;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 400px;
      padding: 2.84301606922126vh 0  2.84301606922126vh 0;
      background-image: url("../../assets/connectbannerM.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width:fit-content;
          text-align: center;
          display: flex;
          margin:0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }
    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      //border-radius: 9px;
      padding: 25px 20px;
      background: transparent;

      .title {

        height: fit-content;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 20px;
      }

      .light {
        margin: 15px auto 0 auto;
        text-align: justify;
        width: 90%;
        height: fit-content;
        font-size: 16px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 22px;

      }


      .pro3 {
        margin-top: 15px;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 15px;
        row-gap:0;

        .grand2 {
          cursor: pointer;
          width: 100%;
          height:fit-content;
          padding:15px 0;
          opacity: 1;
          position: relative;
          .back {
            transition: transform 0.5s ease;
            width: 100%;
            height: 100%;
            background: #d4e4ff;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
          }


          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 180px;
          }
          .title1 {
            opacity: 4;
            margin:15px auto;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: fit-content;
            font-size: 16px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            color: #2168DB;
            line-height: 22px;
          }

          .title2 {
            opacity: 4;
            margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
            text-align: justify;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }


      }



    }
  }

}
</style>
